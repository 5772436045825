/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '@angular/cdk/overlay-prebuilt.css';
//----------Colors----------

//Bootstrap color system
$bs-primary-color: #0D6EFD;
$bs-primary-color-dark: #01409D;
$bs-primary-color-light: #5798F9;
$bs-primary-color-very-light: #AECEFC;
$bs-secondary-color: #6c757d;
$bs-secondary-color-light: #899197;
$bs-secondary-color-very-light: #d4d7da;

//Google material color system
$gl-primary-color: #6200ee;
$gl-primary-color-dark: #3700b3;
$gl-primary-color-light: #efe5fd;
$gl-secondary-color: #03dac6;
$gl-secondary-color-dark: #018786;
$gl-background-color-light: #ffffff;
$gl-background-color-dark: #121212;
$gl-foreground-color-dark: #212121;
$gl-error-color: #b00020;


$sidenav-color: $bs-primary-color-very-light;
$navbar-color: $bs-primary-color;
$container-color: #fafafa;

$table-alternating-row: #e0e3e5;
$bs-table-alternating-row: rgba(0, 0, 0, 0.05);

html, body { height: 100%; }
body { margin: 0;
  font-family: Roboto, "Roboto Light", "Roboto Light", sans-serif;}

//----------General css----------

$shadow-0: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$shadow-1: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$shadow-2: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.username {
  font-size: 20px;
  font-weight: lighter;
  margin: 0 5px 0 0;
  padding: 0;
  width: min-content;
}

.component-title {
  font-size: 34px !important;
  font-weight: lighter !important;
  letter-spacing: 0.25px !important;
  padding: 20px 25px 0 !important;
}

.component-subtitle {
  font-size: 28px;
  font-weight: lighter;
  text-align: center;
  line-height: 28px;
  padding: 20px 25px 0;
}

.card-title {
  font-size: 22px;
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //color handled by custom-theme
}

.utc-time-icon{
  font-size: 28px;
}

.utc-time-label{
  //color handled by custom-theme
  font-size: 12px;
  font-weight: bold;
}

.utc-time-data {
  font-size: 18px;
  font-weight: lighter;
}

.background {
  background-color: #fafafa;
}

//Represents round borders around most of the elements
.round-border {
  margin-right: 2%;
  margin-left: 2%;
  margin-bottom: 1%;
  border-radius: 8px;
  box-shadow: $shadow-2;
  padding: 10px;
  //background-color handled by custom-theme
}

.round-border-light {
  margin-right: 2%;
  margin-left: 2%;
  margin-bottom: 1%;
  border-radius: 10px;
  box-shadow: $shadow-1;
  padding: 10px;
  background-color: whitesmoke;
}

.header-end {
  .mat-sort-header-container:first-child {
    justify-content: end;
  }
}

.header-start {
  .mat-sort-header-container:first-child {
    justify-content: start;
  }
}

//Represents most common width for most of the elements
.w-96 {
  width: 96%;
}

.max-height-table {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
}

//----------Dashboard css----------
.dashboard-title {
  font-size: 16px;
  font-weight: lighter;
}
.percent-increase {
  font-size: 16px;
  font-weight: lighter;
  color: lime;
}
.percent-decrease {
  font-size: 16px;
  font-weight: lighter;
}
.dashboard-subtitle {
  font-size: 18px;
}
.dashboard-card {
  //background-color handled by custom-theme
  height: 80px;
  min-width: 295px;
  max-width: 350px;
  width: auto;
}
.dashboard-card-data {
  font-size: 28px;
  //color handled by custom-theme
  font-weight: lighter;
  margin: 0 !important;
}
.dashboard-card-title {
  font-size: 22px;
  //background-color handled by custom-theme
  font-weight: lighter;
  margin: 0 !important;
  width: min-content;
}
.dashboard-card-time {
  font-size: 13px;
  font-weight: lighter;
  margin: 0 !important;
}
.dashboard-card2 {
  //background-color handled by custom-theme
  height: 190px;
  width: 140px;
  overflow: hidden;
}
.dashboard-card2-data {
  font-size: 18px;
  font-weight: lighter;
  margin: 0 !important;
  width: min-content;
}
.dashboard-card2-icon {
  font-size: 25px;
  height: 25px;
  width: min-content;
  padding-right: 0;
}
.dashboard-datetime {
  background-color: white;
  height: 80px;
  min-width: 440px;
  max-width: 440px;
  margin-left: 0;
}

//----------Reports css----------

//Represents date input component style
.date-input {
  max-width: 150px;
}
.time-input {
  font-size: 18px;
  max-width: 100px;
}
::ng-deep .time-input .mat-form-field-wrapper {
  padding-bottom: 1.05em;
}
::ng-deep mat-toolbar .placeholder {
  display: none !important;
}
.select-input {
  max-width: 150px;
}
.select-input .mat-form-field-wrapper {
  padding-bottom: 1.19em;
}
//Represents a dynamic way of setting column width
@mixin mat-table-columns($columns) {
  .mat-column-
  {
    @each $colName, $props in $columns {

      $width: map-get($props, 'width');

      &#{$colName}
      {
        flex: $width;
        min-width: $width;
        width: $width;
        max-width: $width;
      }
    }
  }
}

//Represents header row of tables
.header-cell {
  //background-color handled by custom-theme
  font-size: 14px;
  font-weight: lighter;
}

.two-rows-header {
  word-break: break-word;
  line-break: auto;
}

.multiple-row-header {
  word-spacing: 1000px;
}

.table-overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-text {
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Represents child header row of tables
.child-header-cell {
  font-size: 13px;
  font-weight: lighter;
}

//Represents hover over the rows of a table
.element-row:not(.expanded-row):hover {
  cursor: pointer;
}

//Represents alternating row color in a table
.alternating {
  //background-color handled by custom-theme
}

//Represents clicked row in a table
.clicked-row {
  //background-color handled by custom-theme
}

//Represents footer row in a table
.footer-cell {
  //background-color handled by custom-theme
  font-size: 13px;
  font-weight: lighter;
}

//Next couple of classes should not be changed
.detail-row {
  height: 0;
}
.element-row td {
  border-bottom-width: 0;
}
.expanded-row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.element-detail {
   overflow: hidden;
   width: 100%;
}
.th {
  word-wrap:break-word;
}

.cell {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.checkbox ::ng-deep .mat-checkbox-inner-container {
  width: 20px;
  height: 20px;
}

.paginator {
}

.small-form-field {
  //max-width: 204px;
}

.user-label {
  font-size: 16px;
  font-weight: lighter;
  width: min-content;
}

.user-name {
  font-size: 34px;
  font-weight: lighter
}
