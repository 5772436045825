@use '~@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$light-primary-palette: (
  50: #efe5fd,
  100: #d4bff9,
  200: #b794f6,
  300: #9965f4,
  400: #7e3ff2,
  500: #6002ee,
  600: #5300e8,
  700: #3d00e0,
  800: #1c00db,
  900: #0000d6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$light-accent-palette: (
  50: #50d4d5,
  100: #29cace,
  200: #00c1c9,
  300: #00b1b7,
  400: #009b9e,
  500: #018787,
  600: #068282,
  700: #0b7d7d,
  800: #117777,
  900: #167272,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$light-warn-palette: (
  50: #fdeaee,
  100: #fbcad2,
  200: #ea959b,
  300: #de6c74,
  400: #e84853,
  500: #ed323b,
  600: #de2839,
  700: #cc1d33,
  800: #bf152c,
  900: #b00020,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$dark-primary-palette: (
  50: #f1e6fe,
  100: #f1e6fe,
  200: #e6d3fe,
  300: #d1acfd,
  400: #c699fc,
  500: #bb86fc,
  600: #b073fc,
  700: #a560fb,
  800: #9b4dfb,
  900: #8526fa,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$dark-accent-palette: (
  50: #d4f6f2,
  100: #d4f6f2,
  200: #d4f6f2,
  300: #d4f6f2,
  400: #92e9dc,
  500: #03dac4,
  600: #00c7ab,
  700: #00b798,
  800: #00a885,
  900: #009a77,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$dark-warn-palette: (
  50: #f7e6eb,
  100: #eec1cd,
  200: #e49cad,
  300: #d87a8f,
  400: #cf6679,
  500: #c75a67,
  600: #b75463,
  700: #a14e5d,
  800: #8b4957,
  900: #683c4a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);


$light-primary: mat.define-palette($light-primary-palette);
$light-accent: mat.define-palette($light-accent-palette);
$light-warn: mat.define-palette($light-warn-palette);

$dark-primary: mat.define-palette($dark-primary-palette);
$dark-accent: mat.define-palette($dark-accent-palette);
$dark-warn: mat.define-palette($dark-warn-palette);

$light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

@mixin body-theme($theme) {
  $_background: map-get($theme, background);
  $app-background-color: mat.get-color-from-palette($_background, background);

  body {
    background-color: $app-background-color;
  }
}

@mixin text-theme($light-theme: false){
  @if($light-theme){
    .login-text {
      color: black;
    }
  }@else {
    .login-text {
      color: white;
    }
  }
}

@mixin card-theme($theme, $light-boolean) {

  @if($light-boolean){
    .round-border {
      background-color: white;
    }
    .round-logo {
      background-color: white;
    }
  }@else {
    .round-border {
      background-color: lighten(#303030, 5%);
    }
    .round-logo {
      background-color: #424242;
    }
  }
}

@mixin header-theme($theme) {
  $_primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($_primary);
  mat-toolbar.header {
    background-color: $primary-color;
    color: white;
  }
}

@mixin primary-theme($theme) {
  $_primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($_primary);

  .card-title {
    color: $primary-color;
  }
  .dashboard-card-title {
    color: $primary-color;
  }
  .profile-header {
    background-color: $primary-color;
  }
  .message-item {
    background-color: $primary-color;
  }
  .user-name {
    color: $primary-color;
  }
  .place-number {
    color: $primary-color;
  }
  .place-suffix {
    color: $primary-color;
  }
  .chat-username {
    color: $primary-color;
  }
}

@mixin accent-theme($theme, $light-boolean: true) {
  $_accent: map-get($theme, accent);
  $accent-color: mat.get-color-from-palette($_accent);
  $_foreground: map-get($theme, background);
  $foreground-color: mat.get-color-from-palette($_foreground);

  .utc-time-label {
    color: $accent-color;
  }
  .dashboard-card2-icon {
    color: $accent-color;
  }
  .profile-item-icon {
    color: $accent-color;
  }
  .round-logo-icon {
    color: $accent-color;
  }
  .box {
    border-bottom: 0.5px solid $accent-color !important;
  }
  .user-icon {
    color: $accent-color;
  }
  .game-title {
    color: $accent-color;
  }
  .span-btn {
    color: $accent-color;
  }
  .demo-text {
    color: $accent-color;
  }
}

@mixin profile-dialog-theme($theme) {
  $_primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($_primary);
  $_accent: map-get($theme, accent);
  $accent-color: mat.get-color-from-palette($_accent);

  .profile-header {
    background-color: #3d3d3d;
  }
  .round-logo-icon {
    color: $accent-color;
  }
}

@mixin table-theme($theme, $light-boolean: false) {
  $_accent: map-get($theme, accent);
  $accent-color: mat.get-color-from-palette($_accent);
  $_primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($_primary);
  $_warn: map-get($theme, warn);
  $warn-color: mat.get-color-from-palette($_warn);
  $_background: map-get($theme, background);
  $app-background-color: mat.get-color-from-palette($_background, background);

  @if($light-boolean) {
    .special-ticket {
      color: #6002ee;
    }
    .header-cell {
      background-color: $accent-color;
      color: whitesmoke;
    }
    .footer-cell {
      background-color: $accent-color;
      color: whitesmoke;
    }
    .alternating {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .element-row:not(.expanded-row):hover{
      background-color: lighten($primary-color, 40%);
    }
    .clicked-row{
      background-color: lighten($primary-color, 40%);
    }
    .child-header-cell {
      background-color: $accent-color;
      color: whitesmoke;
    }
    .child-table-cell {
      background-color: darken($app-background-color, 6);
    }
  }@else {
    .special-ticket {
      color: #3700b3;
    }
    .header-cell {
      background-color: $accent-color;
      color: black;
    }
    .footer-cell {
      background-color: $accent-color;
      color: black;
    }
    .alternating {
      background-color: rgba(0, 0, 0, 0.15);
    }
    .element-row:not(.expanded-row):hover{
      background-color: $warn-color
    }
    .clicked-row{
      background-color: $warn-color;
    }
    .child-header-cell {
      background-color: $accent-color;
      color: black;
    }
    .child-table-cell {
      background-color: lighten($app-background-color, 18);
    }
  }
}

@mixin snackbar-theme($theme, $dark-boolean: false) {
  $_background: map-get($theme, background);
  $background-color: mat.get-color-from-palette($_background, background);
  $_accent: map-get($theme, accent);
  $accent-color: mat.get-color-from-palette($_accent);

  .snackbar{
    @if($dark-boolean){
      background-color: $background-color;
      color: $accent-color;
    }
    max-width: 400px;
  }
}

@mixin chat-room-select-theme($theme, $light-boolean: false) {
  $_accent: map-get($theme, accent);
  $accent-color: mat.get-color-from-palette($_accent);
  $_primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($_primary);
  $_warn: map-get($theme, warn);
  $warn-color: mat.get-color-from-palette($_warn);

  @if($light-boolean) {
    .chat-room-select {
      background-color: $accent-color;
    }
  }@else {
    .chat-room-select {
      background-color: $warn-color;
    }
  }
  .percent-decrease {
    color: $warn-color;
  }
  .owner-color {
    color: $warn-color;
  }
}

@include body-theme($light-theme);
@include mat.all-component-themes($light-theme);
@include header-theme($light-theme);
@include primary-theme($light-theme);
@include card-theme($light-theme, $light-boolean: true);
@include accent-theme($light-theme);
@include table-theme($light-theme, $light-boolean: true);
@include snackbar-theme($light-theme);
@include chat-room-select-theme($light-theme, $light-boolean: true);

.dark-theme {
  @include body-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
  @include primary-theme($dark-theme);
  @include card-theme($dark-theme, $light-boolean: false);
  @include accent-theme($dark-theme, $light-boolean: false);
  @include profile-dialog-theme($dark-theme);
  @include table-theme($dark-theme, $light-boolean: false);
  @include text-theme();
  @include snackbar-theme($dark-theme, $dark-boolean: true);
  @include chat-room-select-theme($dark-theme, $light-boolean: false);
  color-scheme: dark;
}




